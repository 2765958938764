import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
	{
		path: '/',
		name: 'Index',
		component: () => import('@/views/OutIndex/index.vue'),
		redirect: '/Home',
		children: [
			{	//首页
				path: '/Home',
				name: 'Home',
				component: () => import('@/views/Home/index.vue')
			},
			{
				//箱子页面
				path: '/box',
				name: 'box',
				component: () => import('@/views/box/index.vue')
			},
			{
				//金球
				path: '/Jqiu',
				name: 'Jqiu',
				component: () => import('@/views/Jqiu/index.vue')
			},
			{	//开箱(正常)
				path: '/Open',
				name: 'Open',
				component: () => import('@/views/Open/index.vue')
			},
			{	//开箱(正常)
				path: '/Opens',
				name: 'Opens',
				component: () => import('@/views/Jqiu/Opens/index.vue')
			},
			{	//Roll房
				path: '/Roll',
				name: 'Roll',
				component: () => import('@/views/Roll/index.vue')
			},
			{	//Roll房详情
				path: '/room',
				name: 'room',
				component: () => import('@/views/Roll/room.vue')
			},
			{	//Roll房详情
				path: '/Rooms',
				name: 'Rooms',
				component: () => import('@/views/Jqiu/Rooms/index.vue')
			},
			{	//免费箱子
				path: '/Activity',
				name: 'Activity',
				component: () => import('@/views/Activity/index.vue')
			},
			{	//免费箱子开箱
				path: '/Activity_open',
				name: 'Activity_open',
				component: () => import('@/views/Activity/Activity_open')
			},
			{	//饰品商城
				path: '/Mall',
				name: 'Mall',
				component: () => import('@/views/Mall/index.vue')
			},
			{	//幸运开箱(列表)
				path: '/Lucky',
				name: 'Lucky',
				component: () => import('@/views/Lucky/index.vue')
			},
			{	//幸运开箱(开箱)
				path: '/Lucky_open',
				name: 'Lucky_open',
				component: () => import('@/views/Lucky/Lucky_open.vue')
			},
			{	//充值
				path: '/Recharge',
				name: 'Recharge',
				component: () => import('@/views/Recharge/index.vue')
			},
			{	//个人中心
				path: '/Me',
				name: 'Me',
				component: () => import('@/views/Me/index.vue')
			},
			{	//背包
				path: '/Warehouse',
				name: 'Warehouse',
				component: () => import('@/views/Warehouse/index.vue')
			},
			{	//会员推广
				path: '/Extension',
				name: 'Extension',
				component: () => import('@/views/Extension/index.vue')
			},
			{	//修改密码
				path: '/Changepass',
				name: 'Changepass',
				component: () => import('@/views/Changepass/index.vue')
			},
			{	//对战
				path: '/Battle',
				name: 'Battle',
				component: () => import('@/views/Battle/index.vue')
			},
			{//对战详情
				path: '/Combat',
				name: 'Combat',
				component: () => import('@/views/Battle/Combat.vue')
			},
			{	//对战历史
				path: '/Battlehistory',
				name: 'Battlehistory',
				component: () => import('@/views/Battle/Battlehistory.vue')
			},
			{	//升级合约
				path: '/Replacement',
				name: 'Replacement',
				component: () => import('@/views/Replacement/index.vue')
			},
			//抢卡
			{
			    path: '/RushPurchase',
			    name: 'RushPurchase',
			    component: () => import('@/views/RushPurchase/index.vue')
			},
			{
				path: '/PrizeDetails',
				name: 'PrizeDetails',
				component: () => import('@/views/RushPurchase/PrizeDetails.vue')
			},
			//VIP 
			{
				path: '/VIP',
				name: 'VIP',
				component: () => import('@/views/VIP/index.vue')
			},
			{
				path: '/vipActive',
				name: 'vipActive',
				component: () => import('@/views/Warehouse/vipActive.vue')
			},
			//用户协议
			{
				path: '/agreement',
				name: 'agreement',
				component: () => import('@/views/agreement/User.vue')
			},
			//隐私条款
			{
				path: '/Privacy',
				name: 'Privacy',
				component: () => import('@/views/Privacy/Policy.vue')
			},
			//提货规则
			{
				path: '/rule',
				name: 'rule',
				component: () => import('@/views/rule/Rule.vue')
			},
			//概率公平性
			{
				path: '/Chance',
				name: 'Chance',
				component: () => import('@/views/Chance/Chance.vue')
			},
			{
				path: '/CreatingRooms',
				name: 'CreatingRooms',
				component: () => import('@/views/Roll/CreatingRooms.vue')
			}

		]
	},
	{//支付
		path: '/Payment',
		name: 'Payment',
		component: () => import('@/views/Payment/index.vue'),
	}
]

const router = new VueRouter({
  mode: 'hash',
  routes
})

export default router
