import Vue from 'vue'
import Vuex from 'vuex'
import mutations from './mutations.js'
import actions from './actions.js'
Vue.use(Vuex)
const state = {
	demo:'',

	User:{
		email: "",        		//邮箱
		pass: "",         		//密码
		name: "",         		//用户名
		avatar: "",             //头像
		bean: 0,          		//m豆
		invite: "",       		//邀请码
		level: 0,         		//等级
		steam: "",        		//steam 链接
		steam_id:"",            //steamId
		promotion_level:"", 	//推广等级
		total_recharge:"",      //累计充值金豆
		Rebate:"",              //总返利
		recharge_level:"",      //用户充值等级
		charge_rebate:"",       //个人累计充值返佣
		integral:"",            //个人积分
		mobile:"",            //个人积分
		vip_level :''
	},

	IndividualsShow:false, //登出弹框与个人信息显示开关

	//对战相关
	NewGroup:{},//新房间
	join:{},//加入房间
	end:{},//对战结束(奖品已确定)

}


const getters = {

}


export default new Vuex.Store({
  state,
  getters,
  mutations,
  actions
})
