export default {
	
	//demo
	demo(context,val){
		 context.commit('demo',val)
	},
	PostUser(context,u){
		context.commit('PostUser',u)
	},
	PostIndividualsShow(context,val){
		context.commit('PostIndividualsShow',val)
	},
	
	//对战相关
	
	//创建房间
	CreateGroup(context,val){
		context.commit('CreateGroup',val)
	},
	
	//加入房间
	joinGroup(context,val){
		context.commit('joinGroup',val)
	},
	
	//对战结束(奖品已确定)
	endGroup(context,val){
		context.commit('endGroup',val)
	}
}